.post-btn {
  flex: 0 0 auto;

  & a {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 10px 15px;
    border-radius: 5px;
    background-color: var(--orange-800);
    text-decoration: none;
    color: white;
    font-weight: 600;
  }

  & span {
    color: white;
  }
}

@media (max-width: 768px) {
  .post-btn {

    & > a > span {
      display: none;
    }
  }
}