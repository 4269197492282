
.search-box {
  display: flex;
  align-items: center;
  gap: 7px;
  flex: 1 1 auto;
  padding: 3px 5px;

  background-color: inherit;
  border-radius: 5px;

  position: relative;
}

.search-queries {
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100%);
  // outline: 1px solid red;

  & > ul {
    list-style-type: none;
    margin: 0;
    margin-top: 5px;
    box-shadow: 0 0 10px rgba(0 0 0 / 20%);
  }

  & > ul > li {
    // outline: 1px solid gold;
    text-align: left;
    background-color: white;
    padding: 10px;

    cursor: pointer;

    &:hover {
      background-color: #f4f4f4;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }


    & > a {
      color: black;
      text-decoration: none;
    }
  }
}

.search-btn {

  background-color: var(--orange-800);
  border-radius: 5px;

  width: 40px;
  height: 38px;
  
  &:hover {
    background-color: var(--orange-900);
  }
  
  & > button {
    
    background-color: inherit;
    cursor: pointer;
    
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px 13px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > button > svg {
    fill: white;
  }
}

.clear-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-input {
  flex: 1 1 auto;

  border: none;
  background-color: inherit;

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    // color: red;
    // background-color: red;
  }
}

@media (max-width: 768px) {
  
  .post-btn {
    display: none;
  }
}