.chat-panel {

  flex: 0.65;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;

  padding: 15px 0 15px 0;
}

.chat-panel {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;

  padding: 15px 0 15px 0;
}


.header-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  border-bottom: 1px solid lightgray;
  padding: 10px;
  padding-right: 0;
}

.card-header {
  & .avatar img {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.card-footer {
  & img {
    width: 30px;
    height: 30px;
  }
}

.card-body {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;

  text-align: left;

  & .user-name {
    font-weight: 600;
    font-size: 0.875rem;
  }

  & .last-active .status-icon {
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: lightgray;
    border-radius: 50%;
    margin-right: 5px;
  }

  & .last-active {
    font-size: 0.75rem;
    color: black;
  }
}

.message-box {
  flex: 1;
  // background-color: gray;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.messages {
  // flex: 0 1 100%;
  flex: 1 0 200px;

  width: 100%;
  overflow-y: scroll;
  overscroll-behavior: contain;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  
  &::-webkit-scrollbar {
    display: none;
  }

  word-break: break-all;

  background-color: lightgray;

  & > ul {
    list-style-type: none;

    // height: 100%;
    padding-inline: 20px;
    margin-top: 0;
    padding-top: 10px;
    // margin-bottom: 0;

    & > li {
      margin-bottom: 5px;
    }
  }

  & > ul > li .message-content {
    width: 100%;

    display: flex;
    align-items: center;
    gap: 5px;

    &.self {
      justify-content: flex-end;
      & .avatar {
        order: 1;
      }
    }
    
    &.other {
      justify-content: flex-start;
      & .avatar {
        order: -1;
      }
    }
  }

}

.avatar > img {
  border-radius: 50%;
  vertical-align: middle;
}

.scroll-to-end-btn {

  position: -webkit-sticky;
  position: sticky;

  // position: absolute;

  background-color: transparent;

  margin: 0 auto;
  border: 0;
  width: 30px;
  // border-radius: 50%;
  padding: 5px 7px;
  // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    // box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  }

  & > svg {
    // vertical-align: middle;
  }

  transition: transform 300ms ease;
}

.scroll-to-top-btn {
  @extend .scroll-to-end-btn;
  top: 0;

  &.hidden {
    transform: translateY(-50px);
  }
}

.scroll-to-bottom-btn {
  @extend .scroll-to-end-btn;
  
  bottom: 0;

  &.hidden {
    position: fixed;
    transform: translateY(50px);
  }
}

.quick-messages {

  padding-inline: 10px;

  &>ul {
    flex: 0;
    list-style-type: none;

    display: flex;
    gap: 7px;
    overflow-x: scroll;
    padding: 5px 0 5px 0;
    margin-top: 0;

    & li {
      flex-shrink: 0;
      padding: 5px 7px;
      background-color: #f4f4f4;
      border-radius: 16px;
      cursor: pointer;
    }
  }
}

.chat-box {
  max-height: 100px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;

  padding-inline: 10px;

  & .more-actions img,
  & .send-button>svg {
    flex: 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }

  & .send-button {

    border: 0;
    background-color: transparent;
    border-radius: 50%;
    padding: 10px;
    transition: background-color 300ms ease;

    &:hover {
      background-color: lightgray;
    }

    &:focus {
      outline: 1px solid gray;
    }

    &:active {
      opacity: 0.8;
    }
  }

  & .chat-input {
    flex: 1;
    // padding: 10px 0;
  }

  & textarea {
    width: 100%;

    overflow: hidden;
    overflow-wrap: break-word;

    height: 40px;
    max-height: 100px;
    resize: none;

    border: 0;
    border-radius: 16px;
    padding: 10px;

    background-color: #f4f4f4;
    position: relative;

    &::placeholder {
      font-size: 0.875rem;
      text-align: left;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }

    &:focus {
      outline: 0;
    }
  }
}