@import '@styles/scss/_typography';



@mixin shortener() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contact-panel {

  flex: 0.35;
  
  height: 100%;
  background-color: white;

  & .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px;
  }

  & .contacts {
    flex: 1;
  
    display: flex;
    flex-direction: column;

    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
    & > ul {
      list-style-type: none;
    }
  }

}

.settings {

  & > .settings-icon {
    height: 100%;
  }

  & > .settings-icon > img {
    vertical-align: middle;
  }
}

.message-filter-menu {
  position: relative;

  & .menu-content {
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
  }

  & .menu-btn {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    padding: 7px;
    border: 1px solid lightgray;
    background-color: white;
    cursor: pointer;

    & img {
      vertical-align: middle;
    }
  }
}

.message-filter-options {
  & ul {
    list-style-type: none;
    width: 200px;
    text-align: left;
  }

  & li {
    background-color: white;
    padding: 15px;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
  }
}


.contact-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  padding: 10px;
  background-color: #f4f4f4;

  &:hover {
    background-color: lightgray;
  }
}


.card-header {

  & .avatar img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

}

.card-body {
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7px;

  text-align: left;

  & .card-title .user-name {
    font-weight: 600;
  }

  & .card-title .last-active {
    font-size: 0.875rem;
  }

  & .last-message span {
    display: inline-block;
    width: 100%;

    color: gray;
    font-size: 0.875rem;

    @include text-collapse(1);
  }
}

.card-footer {
  & .product-image img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
}

.card-body {
  flex: 1;
}