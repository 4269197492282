.wrapper {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background-color: inherit;
  position: relative;

  & > * {
    padding: 10px;
    padding-inline: 0;
  }
}

.action {
  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    text-decoration: none;
  }

  &:nth-child(even) svg {
    font-size: 1.6em;
  }

  &:nth-child(odd) svg {
    font-size: 1.7em;
  }
}

.toolbar-menu-btn {
  display: none;
}

.logo {
  text-align: left;
  flex: 0.3 1 auto;
  padding-top: 0;

  & img {
    height: 30px;
    object-fit: contain;
  }
}

.toolbar {
  flex: 0.7 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;

  & span {
    color: black;
  }
}

.dropdown-menu {
  // &:hover .menu-btn * {
  //   opacity: 0.8;
  // }
  cursor: pointer;
}

.menu-content {
  display: block;
  position: absolute;
  z-index: 999;
  top: 100%;
  right: 0;

  overflow-y: auto;
  width: 300px;
  height: 450px;

  background-color: inherit;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  & a:first-child:hover {
    opacity: 1;
  }
  
  & a:not(first-child):hover {
    background-color: whitesmoke;
  }

  & .menu-item:first-child a {
    padding: 8px 12px;
    vertical-align: middle;
  }
}

.menu-item {
  display: flex;
  gap: 15px;

  & a {
    flex: 1 0 auto;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    padding: 8px 12px;
  
    background-color: white;
    // color: inherit;
    text-decoration: none;
  }

  & a span {
    flex: 1;
    text-align: left;
    padding-left: 15px;
  }
}

.menu-title {
  background-color: #f5f5f5;
  padding: 10px;
  text-align: left;
  font-weight: 600;
}

@media (max-width: 768px) {
  .logo {
    flex: 1;
    text-align: center;
  }

  .toolbar {
    flex: 0 1 auto;
  }

  .toolbar .action:not(.chat) {
    display: none;
  }

  .chat span {
    display: none;
  }

}

@media (max-width: 576px) {
  .toolbar {
    flex: 1;
  }
}

@media (max-width: 420px) {

  .wrapper {
    gap: 10px;
    justify-content: space-between;
  }

  .toolbar-menu-btn {
    display: initial;
    flex: 1;
    text-align: left;
  }

  .logo {
    flex: 1;
  }

  .toolbar {
    gap: 0;
  }
}