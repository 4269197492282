.wrapper {

  // background-color: white;
  background-color: inherit;
  overflow-y: scroll;
  overscroll-behavior: contain;

  height: 100vh;
  width: max(250px, 25vh);

  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  transition: translate 500ms ease;
  translate: -1000px 0;

  &.opened {
    translate: 0;
  }

  & > ul {
    margin: 0;
  }

  // & > ul > li:first-child {
  //   text-align: center;
  // }
}

.overlay {

  position: fixed;
  inset: 0 0 0 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: default;

  display: none;

  &.opened {
    display: initial;
  }

}