.wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-action {
  flex: 1 1 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 10px;
  background-color: #f4f4f4;
  position: relative;
}

.search-box {
  display: flex;
  align-items: center;
  gap: 7px;
  flex: 1 1 auto;
  padding: 3px 5px;

  background-color: inherit;
  border-radius: 5px;

  position: relative;
}

.search-queries {
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100%);
  // outline: 1px solid red;

  & > ul {
    list-style-type: none;
    margin: 0;
    margin-top: 5px;
    box-shadow: 0 0 10px rgba(0 0 0 / 20%);
  }

  & > ul > li {
    // outline: 1px solid gold;
    text-align: left;
    background-color: white;
    padding: 10px;

    &:hover {
      background-color: #f4f4f4;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.search-btn {

  // width: 38px;
  // height: 38px;

  background-color: var(--orange-800);
  border-radius: 5px;

  &:hover {
    background-color: var(--orange-900);
  }
  
  & > button {
    
    background-color: inherit;
    cursor: pointer;
    
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px 13px;
    border-radius: 5px;
  }

  & > button > svg {
    vertical-align: middle;
    fill: white;
  }
}

.clear-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-input {
  flex: 1 1 auto;

  border: none;
  background-color: inherit;

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    // color: red;
    // background-color: red;
  }
}

// .dropdown-menu {
//   display: flex;
//   align-items: center;
//   flex: 0 1 100px;

//   padding: 10px;

//   border-right: 1px solid lightgrey;
//   background-color: inherit;
// }

// .menu-content {
//   width: 100px;

//   position: absolute;
//   top: 100%;
//   left: 0;
//   z-index: 999;

//   display: flex;
//   flex-direction: column;
// }

// .menu-item {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 7px;

//   padding: 12px 7px;
//   font-size: 0.875rem;
//   background-color: white;
//   box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

//   &:not(last-child) {
//     border-bottom: 1px solid lightgray;
//   }

//   & > svg {
//     fill: var(--orange-800);
//   }

//   &:hover {
//     background-color: whitesmoke;
//   }
// }

// .menu-btn {
//   flex: 1 1 auto;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 10px;

//   text-align: center;

//   & span {
//     font-size: 0.875rem;
//     flex: 1 0 auto;
//   }
// }

// .post-btn {
//   flex: 0 0 auto;

//   & a {
//     display: flex;
//     align-items: center;
//     gap: 8px;

//     padding: 10px 15px;
//     border-radius: 5px;
//     background-color: var(--orange-800);
//     text-decoration: none;
//     color: white;
//     font-weight: 600;
//   }

//   & span {
//     color: white;
//   }
// }

@media (max-width: 768px) {
  .post-btn {
    display: none;
  }

  .search-btn{
    display: none;
  }

}

// @media (max-width: 420px) {

//   .dropdown-menu {
//     flex: 0 0 auto;
//   }
// }