.dropdown-menu {
  // &:hover .menu-btn * {
  //   opacity: 0.8;
  // }
  display: flex;
  align-items: center;

  cursor: pointer;
}

.menu-btn {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  & > svg {
    vertical-align: middle;
  }
}

.menu-content {
  display: block;
  position: absolute;
  z-index: 999;
  top: 100%;
  right: 0;

  overflow-y: auto;
  width: 300px;
  height: 450px;

  background-color: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  & a:first-child:hover {
    opacity: 1;
  }
  
  & a:not(first-child):hover {
    background-color: whitesmoke;
  }

  & .menu-item:first-child a {
    padding: 8px 12px;
    vertical-align: middle;
  }
}

.menu-item {
  display: flex;
  gap: 15px;

  & a {
    flex: 1 0 auto;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    padding: 8px 12px;
  
    background-color: white;
    // color: inherit;
    text-decoration: none;
  }

  & a span {
    flex: 1;
    text-align: left;
    padding-left: 15px;
  }
}

.menu-title {
  background-color: #f5f5f5;
  padding: 10px;
  text-align: left;
  font-weight: 600;
}

@media (max-width: 420px) {
  .menu-btn {
    display: none;
  }

}