@import '@styles/scss/_typography';


.wrapper {
  height: 100%;
}

.title {
  font-size: 1.125rem;
}

.grid {
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  // grid-auto-flow: row;
  grid-auto-rows: minmax(100px, auto);
  gap: 15px;
  
}

.latest-products {
  grid-column: 1 / span 1;
  grid-row: 1 / span 5;
  position: relative;

  & .title-body {
    max-height: 600px;

    overflow-y: scroll;
    padding-top: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  & > .title-wrapper {

    position: sticky;
    top: 0;

    background-color: #202020;
    padding-top: 15px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    & > .title-head > .icon > svg {
      fill: var(--red-500, gold);
      vertical-align: middle;
    }

    & > .title-head > .title {
      font-size: 1.125rem;
      color: var(--red-500);
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;
    }

  }

  & .fade-in {

    text-align: center;
    padding-block: 10px;

    height: 100px;
    width: 100%; 
    
    position: absolute; 
    bottom: -20px; 
    left: 0;
    right: 0;

    background-image: linear-gradient(to top, #202020, transparent);

    & > svg {
      fill: white;
      vertical-align: middle;
    }
  }

  & .more-products-btn {

    width: 100%;
    margin: auto;
    cursor: pointer;
    isolation: isolate;
    z-index: 1;

    background-color: transparent;
    color: white;
    border: 0;
    padding: 20px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1ch;

    transition: box-shadow 500ms ease;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(0 0 0 / 90%);
    }
  }
}


.latest-products-content {

  color: white;

  & > ul {
    list-style-type: none;
  }

  & > ul > li {
    display: flex;
    align-items: center;
    gap: 10px;
    // margin-bottom: 10px;
    padding-block: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid gray;
    }

    // &::before {
    //   content: "";
    //   box-sizing: border-box;
    //   display: inline-block;

    //   height: 70px;
    //   width: 2px;
    //   background-color: gray;
    // }
  }

  & .product-info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;
  }

  & .post-title {
    color: lightgray;
  }

  & .post-title, & .username {
    @include text-collapse;

  }

  & .price {
    color: var(--red-400, red);
  }

  & .created-at {
    color: lightgray;
  }

  & .username {
    color: lightgray;
  }
}


.title-wrapper {

  display: flex;
  flex-direction: column;
  gap: 20px;
  
  height: 100%;
  text-align: left;

  & .title-head {
    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  & .title-head > .title {
    font-size: 1.125rem;
    color: var(--orange-800);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
  }

  & .title-body {
    color: lightgray;
    
    & .data {
      font-size: 2.5rem;
    }

    & .unit {
      font-size: 1.75rem;
    }

  }

}

.total-products {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;

  & .title-wrapper {

    & > .title-head > .icon > svg {
      fill: var(--orange-800, orange);
      vertical-align: middle;
    }
  }

}

.total-users {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;

  & > .title-wrapper {

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // gap: 10px;

    & > .title-head > .icon > svg {
      fill: var(--yellow-800, gold);
      vertical-align: middle;
    }

    & > .title-head > .title {
      font-size: 1.125rem;
      color: var(--yellow-800);
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;
    }

  }
}

.activities {
  grid-column: 4 / span 1;
  grid-row: 1 / span 4;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // align-items: center;

  & > .title-wrapper {

    flex: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    & > .title-head > .icon > svg {
      fill: var(--green-500, green);
      vertical-align: middle;
    }

    & > .title-head > .title {
      text-align: left;
      font-size: 1.125rem;
      color: var(--green-500);
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;
    }

  }

  & .timeline {
    color: white;
  }

}

.users-by-role {
  
  grid-column: 2 / span 2;
  grid-row: 6 / span 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > .title-wrapper {

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    & > .title-head > .icon > svg {
      fill: var(--orange-500, orange);
      vertical-align: middle;
    }

    & > .title-head > .title {
      font-size: 1.125rem;
      color: var(--orange-500);
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;
    }

  }

}

.pie {

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 200px;
}

.products-by-type {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;

  & > .title-wrapper {

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    & > .title-head > .icon > svg {
      fill: var(--yellow-600, yellow);
      vertical-align: middle;
    }

    & > .title-head > .title {
      font-size: 1.125rem;
      color: var(--yellow-600);
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;
    }

  }
}


.products-by-category {
  grid-column: 2 / span 2;
  grid-row: 4 / span 2;

  & > .title-wrapper {

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    & > .title-head > .icon > svg {
      fill: var(--yellow-300, yellow);
      vertical-align: middle;
    }

    & > .title-head > .title {
      font-size: 1.125rem;
      color: var(--yellow-300);
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;
    }

  }
}

@media (max-width: 1400px) {

  .latest-products {
    grid-column: 1 / span 2;
    grid-row: 1 / span 4;
  }

  .total-users {
    grid-column: 3 / span 2;
    grid-row: 1 / span 1;
  }

  .users-by-role {
    grid-column: 1 / span 4;
    grid-row: 5 / span 2;
  }
  
  .products-by-type {
    grid-column: 1 / span 4;
    grid-row: 7 / span 2;
  }

  .products-by-category {
    grid-column: 1 / span 4;
    grid-row: 9 / span 2;
  }

  .total-products {
    grid-column: 3 / span 2;
    grid-row: 2 / span 1;
  }

  .activities {
    grid-column: 1 / span 4;
    grid-row: 11 / span 2;
  }

}


@media (max-width: 768px) {

  .latest-products {
    grid-column: 1 / span 4;
    grid-row: 1 / span 4;
  }

  .total-users {
    grid-column: 1 / span 4;
    grid-row: 5 / span 2;
  }

  .total-products {
    grid-column: 1 / span 4;
    grid-row: 7 / span 2;
  }

  .products-by-category {
    grid-column: 1 / span 4;
    grid-row: 11 / span 2;
  }

  .products-by-type {
    grid-column: 1 / span 4;
    grid-row: 13 / span 2;
  }

  .users-by-role {
    grid-column: 1 / span 4;
    grid-row: 9 / span 2;
  }

  .activities {
    grid-column: 1 / span 4;
    grid-row: 15 / span 4;
  }

}
