.dropdown-menu {

  display: flex;
  align-items: center;
  flex: 0 1 100px;

  padding: 10px;

  border-right: 1px solid lightgrey;
  background-color: inherit;
}

.menu-content {
  width: 100px;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;

  display: flex;
  flex-direction: column;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;

  padding: 12px 7px;
  font-size: 0.875rem;
  background-color: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  &:not(last-child) {
    border-bottom: 1px solid lightgray;
  }

  & > svg {
    fill: var(--orange-800);
  }

  &:hover {
    background-color: whitesmoke;
  }
}

.menu-btn {

  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  text-align: left;

  & span {
    font-size: 0.875rem;
    flex: 1 0 auto;
  }
}


@media (max-width: 420px) {

  .dropdown-menu {
    flex: 0 0 auto;
  }
}